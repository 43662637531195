import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let cookieAck = localStorage.getItem("cookieAcknowledged");
    console.log(cookieAck);
    if(cookieAck === null) {
      this.element.classList.remove('hidden');
    } else {
      this.element.classList.add('hidden');
    }

  }

  cookieAcknowledged() {
    localStorage.setItem("cookieAcknowledged", true);
    this.element.remove();
  }

  cookieRejected() {
    localStorage.setItem("cookieAcknowledged", false);
    this.element.remove();
  }
}